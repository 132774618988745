import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function EditUserModal(props) {
  const { edituseraction } = props;
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  React.useEffect(() => {
    setUsername(localStorage.getItem('editUsername'));
    setRole(localStorage.getItem('editRoleName'));
  }, [
    localStorage.getItem('editUsername'),
    localStorage.getItem('editRoleName'),
  ]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control"
            placeholder="First name"
          />
        </div>

        <div className="mb-3">
          <label>Role</label>
          <select
            placeholder="Enter email"
            value={role}
            className="form-control"
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="">Select Role</option>
            <option value="ADMIN">Admin</option>
            <option value="EDITOR">Editor</option>
            <option value="USER">User</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => edituseraction(username, role)}>Edit</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditUserModal;
