import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useIdleTimer } from 'react-idle-timer';
import withNavigate from '../../components/views/HOC/useNavigate';
import useRefreshToken from './useRefreshToken';

let countdownInterval;
let timeout;

function VerifyAuth({ navigate }) {
  const newToken = useRefreshToken();
  const userName = localStorage.getItem('username');
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const [show, setShow] = useState(false);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const onActive = () => {
    if (!show) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };
  const onIdle = () => {
    const delay = 1000 * 1;
    if (isAuthenticated ) {
      timeout = setTimeout(() => {
        let countDown = 10;
        setShow(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            logout(true);
          }
        }, 1000);}, delay)
    }
  };
  const logout = async (isTimedOut = false) => {
    try{
    localStorage.clear();
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    setShow(false);
    navigate('/signin');
    clearSessionInterval();
    clearSessionTimeout();
  }catch(err){
    console.error(err);
  }
  };
  const idleTimerRef = useIdleTimer({ onIdle, timeout: 1000 * 540 * 60, onActive });
 
  const handleClose = () => {
    setShow(false);
    clearSessionInterval();
    clearSessionTimeout();
    newToken();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hi {userName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>There is no activity session will logout! {timeoutCountdown}s</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={logout}>
            Logout
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default withNavigate(VerifyAuth);
