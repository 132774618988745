import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import withNavigate from '../views/HOC/useNavigate';
import axios from '../../service/axiosServices/axios';
import { useAuth } from '../../service/axiosServices/auth';
import { Notyf } from 'notyf';


const notyf = new Notyf({
  duration: 2000,
  dismissiblea: true,
  position: {
    x: 'right',
    y: 'top',
  },
});

function Login({ navigate }) {
  const { setAuth } = useAuth();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  // const { signin, isAuthenticated } = React.useContext(AuthContext);
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  const onLogin = () => {
    axios
      .post(
        '/authtoken',
        JSON.stringify({
          username: user,
          password: password,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorMessage = error.response.data.message;
          const newErr = errorMessage.split(';');
          let newErrrr = newErr[0];
          notyf.error(newErrrr);
          //  console.log(error.response.data.message);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        }
      })
      .then((response) => {
        if (response.data.status === 'Success') {
          try {
            const accessToken = response?.data?.data?.token;
            const roles = response?.data?.data?.roleName;
            setUser(response?.data?.data?.username);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('userRole', response.data.data.roleName);
            localStorage.setItem('username', response.data.data.username);
            document.cookie = 'token=' + response?.data?.data?.token;
            setAuth({ user, password, roles, accessToken });
            navigate('/');
          } catch (err) {
            console.log(err);
          }
        }else if(response.data.status === 'Failure'){
          notyf.error(response.data.message);
        }
      });
  };


  React.useState(() => {}, [isAuthenticated]);

  return (
    <div className="login-container">
      {isAuthenticated && <Navigate to="/" />}
      {!isAuthenticated && (
        <form onSubmit={onLogin}>
          <h3>Sign In</h3>

          <div className="mb-3">
            <label>Username</label>
            <input
              type="text"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              className="form-control"
              placeholder="Enter email"
            />
          </div>

          <div className="mb-3">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Enter password"
            />
          </div>

          {/* <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Remember me
              </label>
            </div>
          </div> */}

          <div className="d-grid">
            <button type="button" onClick={onLogin} className="btn btn-primary">
              Submit
            </button>
          </div>
          {/* <p className="forgot-password text-right">
            Forgot <a href="javscript: void(0)">password?</a>
          </p> */}
        </form>
      )}
    </div>
  );
}

export default withNavigate(Login);
