import React, { useEffect, useState } from 'react';
import '../../App.css';
import BaseAPI from '../../service/axiosServices/baseAPI';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Notyf } from 'notyf';
import withNavigate from '../views/HOC/useNavigate';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { constants } from '../../constants';

function JobsListing({ navigate }) {
  const axiosPrivate = BaseAPI();
  const [apiResponseData, setAPIResponseData] = useState([]);
  const [createdJobData, setCreatedJobData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [actionShow, setActionShow] = useState(true);
  const userRole = localStorage.getItem('userRole');

  const notyf = new Notyf({
    duration: 1000,
    dismissiblea: true,
    position: {
      x: 'right',
      y: 'top',
    },
  });

  function actionCentre() {
    if (userRole === 'ADMIN') {
      setActionShow(false);
    }
  }
  const skillFormatter = (cell, row) => {
    return (
      <div>
        {cell.map((skill) => (
          <span className="skill-tag" key={`skill_${skill.id}`}>
            {skill.skill_name}
          </span>
        ))}
      </div>
    );
  };

  const CreateJob = () => {
    navigate(`/CreateJobs`);
  };

  const editCreatedJob = (id) => {
    navigate(`/updateCreatedJob/${id}`);
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="action-wrapper">
        <span onClick={() => editCreatedJob(row.id)}>
          <img src="/assets/edit-icon.png" alt="" />
        </span>
        <span onClick={() => deleteCreatedJob(row.id)}>
          <img src="/assets/delete-icon.png" alt="" />
        </span>
      </div>
    );
  };
  const fileFormatter = (cell, row) => {
    return (
      <div>
        {row.filePath && (
          <a
            href={`${constants.baseURL}/candidates/downloadProfile/${row.id}`}
            download
          >
            Download File
          </a>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
      csvExport: false,
    },
    {
      dataField: 'jobId',
      text: 'Training Id',
    },
    {
      dataField: 'jobTitle',
      text: 'Training Title',
    },
    {
      dataField: 'jobDesc',
      text: 'Training Description',
    },
    {
      dataField: 'jobLocation',
      text: 'Training Location',
    },
    {
      dataField: 'jobExperince',
      text: 'Experince',
    },
    {
      dataField: 'jobStatus',
      text: 'Status',
    },
    {
      dataField: 'skills',
      formatter: skillFormatter,
      csvFormatter: (cell, row, rowIndex) => {
        return cell.map((skill) => skill.skill_name).join(',');
      },
      text: 'Skill',
    },
    {
      dataField: 'filePath',
      formatter: fileFormatter,
      text: 'File',
    },
    {
      dataField: 'df1',
      isDummyField: true,
      formatter: actionFormatter,
      text: 'Actions',
      csvExport: false,
      hidden: actionShow,
    },
  ];
  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ color: 'red' }}
      >
        {text}
      </a>
    </li>
  );

  const options = {
    //sizePerPageOptionRenderer,
    page: page + 1,
    //sizePerPage: 10,
    totalSize: totalData,
    hideSizePerPage: false,
  };
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
  };
  const deleteCreatedJob = (id) => {
    axiosPrivate.delete(`/candidates/deleteJob/${id}`).then((response) => {
      if (response.data.status === 'Success') {
        notyf.success('Candidate record deleted successfully !');
        setIsLoading(false);
        fetchData(page, pageSize);
      } else {
        notyf.error('Something went wrong, please try again !');
      }
    });
  };

  async function fetchData(pageIndex, sizePerPage) {
    const response = await axiosPrivate.post(
      '/candidates/getfilterjobs',
      JSON.stringify({
        active: 1,
        pageNumber: `${pageIndex}`,
        pageSize: `${sizePerPage}`,
        sort: 'createdAt',
      })
    );
    setCreatedJobData(response.data.data);
    setTotalData(response.data.totalData);
    setAPIResponseData(response.data.data);
    setPage(pageIndex);
    setPageSize(sizePerPage);
    setIsLoading(false);
  }

  const onSearch = (e) => {
    const searchString = e.target.value;
    if (searchString) {
      const updatedcreatedJobData = apiResponseData.filter((obj) =>
        Object.values(obj).some((val) => {
          if (Array.isArray(val))
            return val
              .map((skill) => skill.skill_name.toLowerCase())
              ?.includes(searchString.toLowerCase());
          else return val?.toString()?.includes(searchString.toLowerCase());
        })
      );
      setCreatedJobData(updatedcreatedJobData);
    } else {
      setIsLoading(false);
      setCreatedJobData(apiResponseData);
    }
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    fetchData(page - 1, sizePerPage);
  };

  useEffect(() => {
    setIsLoading(false);
    fetchData(0, 10);
    actionCentre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading && (
        <div className="loading-box">
          <img className="loader-logo" src="assets/loader.png" alt="" />
          <div className="loader"></div>
        </div>
      )}
      <h2>Job List</h2>
      <div className="home-container content_box">
        <div className="row box-header">
          <div className="col-md-6">
            <div className="box-title">Job List</div>
          </div>
          <div className="col-md-4 text-right">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search..."
              onKeyUp={(e) => onSearch(e)}
            />
          </div>
          <div className="col-md-2 text-right d-flex justify-content-around">
            {userRole === 'ADMIN' && (
              <button
                type="button"
                onClick={() => CreateJob()}
                className="btn btn-primary ml-1"
              >
                +
              </button>
            )}
          </div>
        </div>
        {createdJobData && totalData  && (
          <div>
            <BootstrapTable
              striped
              hover
              condensed
              keyField="id"
              data={createdJobData}
              columns={columns}
              selectRow={selectRow}
              remote={{ pagination: true, filter: false, sort: false }}
              pagination={paginationFactory(options)}
              onTableChange={handleTableChange}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default withNavigate(JobsListing);
