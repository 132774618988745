import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Routes, Route } from 'react-router-dom';
import Login from './components/login/login';
import SignUp from './components/signup/signup';
import SignUpAsCandidate from './components/candidate/signupAsCandidate';
import Home from './components/home/home';
import ProtectedRoute from './components/routes/ProtectedRoute';
import Header from './components/home/header';
import CreateCandidate from './components/candidate/createCandidate';
import Users from './components/user/users';
import CreateJobs from './components/jobs/createJobs';
import Joblisting from './components/jobs/jobListing';
import Findjob from './components/jobs/findjob';
import JobDetails from './components/jobs/jobDetails';
import VerifyAuth from './service/axiosServices/verifyAuth';

// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory();

const ROLES = {
  User: 'USER',
  Admin: 'ADMIN',
};

function App() {
  return (
    <>
      <Header />
      <VerifyAuth />
      <div className="App">
        <div className="auth-inner">
          <Routes>
            <Route exact path="/signin" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route
              exact
              path="/signupAsCandidate"
              element={<SignUpAsCandidate />}
            />
            <Route exact path="/createjobs" element={<CreateJobs />} />
            <Route exact path="/findjob" element={<Findjob />} />
            <Route exact path="/findjob/:id" element={<JobDetails />} />
            <Route element={<ProtectedRoute />}>
              <Route exact path="/jobslisting" element={<Joblisting />} />
              <Route exact path="/" element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route
                exact
                path="/createCandidate"
                element={<CreateCandidate />}
              />
              <Route
                exact
                path="/candidate/:id"
                element={<CreateCandidate />}
              />
              <Route
                exact
                path="/updateCreatedJob/:id"
                element={<CreateJobs />}
              />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
