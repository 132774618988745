import React, { useReducer } from "react";

const CreateDataContext =  (reducer, actions, defaultValue) => {
  const Context = React.createContext();

  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions = {};
    for (const key in actions) {
      if (Object.prototype.hasOwnProperty.call(actions, key)) {
        boundActions[key] = actions[key](dispatch);
      }
    }
    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

export default CreateDataContext;