import { useAuth } from './auth';
import BaseAPI from './baseAPI';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const axiosPrivate = BaseAPI();

  const refresh = async () => {
    const response = await axiosPrivate.get('/refreshtoken', {
      headers: { isRefreshToken: 'true' },
    });
    setAuth((prev) => {
     // console.log(JSON.stringify(prev));
     // console.log(response.data.data.token);
      return { ...prev, accessToken: response.data.data.token };
    });
    document.cookie = 'token=' + response?.data?.data?.token;
    return response.data.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
