import React, { useEffect, useState } from 'react';
import '../../App.css';
import withNavigate from '../views/HOC/useNavigate';
import Button from 'react-bootstrap/Button';
import { Card, Row, Col, Container } from 'react-bootstrap';
import BaseAPI from '../../service/axiosServices/baseAPI';
import { Notyf } from 'notyf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { constants } from '../../constants';
import {
  faLocationDot,
  faMagnifyingGlass,
  faEnvelope,
  faShare,
  fapep,
} from '@fortawesome/free-solid-svg-icons';

const defaultFormState = {
  search: '',
};
const notyf = new Notyf({
  duration: 2000,
  dismissiblea: true,
  position: {
    x: 'right',
    y: 'top',
  },
});
function FindListing({ navigate }) {
  const axiosPrivate = BaseAPI();
  const [formState, setFormState] = useState(defaultFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [page, setPage] = useState(0);
  const [HasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const userRole = localStorage.getItem('userRole');

  const formFieldChange = (e) => {
    const fieldName = e.target.getAttribute('data-name');
    const fieldVal = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: fieldVal,
    });
  };

  const jobDetails = (id) => {
    navigate(`/findjob/${id}`);
  };

  async function fetchData() {
    setIsFetching(true);
    const response = await axiosPrivate.post(
      '/candidates/getfilterjobs',
      JSON.stringify({
        active: 1,
        pageNumber: page,
        pageSize: '10',
        sort: 'createdAt',
      })
    );
    if (response.data.status === 'Success') {
      setJobData((prevData) => {
        return [
          ...new Set([
            ...prevData,
            ...response.data.data.filter((job) => job.jobStatus !== 'Close'),
          ]),
        ];
      });
      setIsLoading(false);
      setPage((prevPageNumber) => prevPageNumber + 1);
      setHasMore(response.data.data.length > 0);
      setIsFetching(false);
    } else {
      notyf.error('Something went wrong, please try again later !');
    }
  }
  async function onFormSubmit() {
    setIsLoading(true);
    const response = await axiosPrivate.post(
      '/candidates/getfilterjobs',
      JSON.stringify({
        active: 1,
        pageNumber: '0',
        pageSize: '10',
        sort: 'createdAt',
        search: formState.search,
      })
    );
    setJobData(response.data.data);
    setIsLoading(false);
  }

  async function sendMailToCandidate(job) {
    const formData = new FormData();
    const payloadData = job;
    formData.append('info', JSON.stringify({ ...payloadData }));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const response = await axiosPrivate.post(
      '/candidates/sendMailToCandidate',
      formData,
      config
    );
    if (response.data.status === 'Success') {
      notyf.success('Mail send successfully !');
    } else {
      notyf.error('Something went wrong, please try again later !');
    }
  }
  const onSubmit = () => {
    onFormSubmit();
  };

  const sendmail = (job, e) => {
    sendMailToCandidate(job);
  };

  function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = window.location.href + '/' + text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

  const shareJob = (id) => {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(window.location.href + '/' + id);
      } else {
        unsecuredCopyToClipboard(id);
      }
      notyf.success('URL copied to ClipBoard');
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-box">
          <img className="loader-logo" src="assets/loader.png" alt="" />
          <div className="loader"></div>
        </div>
      )}
      <Container>
        <form style={{ padding: '20px' }}>
          <div className="form_body">
            <div className="row">
              <div className="col-10" style={{ width: '87%' }}>
                <input
                  data-name="search"
                  className="form-control"
                  value={formState.search}
                  placeholder="Search"
                  onChange={formFieldChange}
                />
              </div>
              <div className="col-2" style={{ width: '13%' }}>
                <Button
                  className="search-button"
                  variant="primary"
                  onClick={onSubmit}
                >
                  <FontAwesomeIcon
                    className="fontAwosome_change"
                    icon={faMagnifyingGlass}
                  />
                  Find Job
                </Button>
              </div>
            </div>
          </div>
        </form>

        <Row style={{ padding: '20px' }}>
          <div>
            {jobData.length > 0 &&
              jobData.map((job, k) => (
                <Col key={k} xs={12} md={12} lg={12}>
                  <Card className="card-design">
                    <Card.Body>
                      <Row>
                        <Card.Title className="job-title">
                          <span onClick={() => jobDetails(job.id)}>
                            {job.jobTitle}
                          </span>
                          <Card.Subtitle
                            className="text-muted card-sub"
                            style={{ margin: 0, fontSize: '12px' }}
                          >
                            ID: {job.jobId}
                          </Card.Subtitle>
                        </Card.Title>
                      </Row>
                      <Card.Text className="job-description">
                        {job.jobDesc}
                      </Card.Text>
                      <Card.Text>
                        <FontAwesomeIcon icon={faLocationDot} />{' '}
                        <span className="light-text">{job.jobLocation}</span>
                      </Card.Text>
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          <Card.Text>
                            Experince required:{' '}
                            <span className="light-text">
                              {job.jobExperince}
                            </span>
                          </Card.Text>

                          <Card.Text>
                            Training Mode:{' '}
                            <span className="light-text">{job.jobMode}</span>
                          </Card.Text>
                          <Card.Text>
                            Fulfilment by:{' '}
                            <span className="light-text">
                              {job.fulfillmentby}
                            </span>
                          </Card.Text>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <Card.Text>
                            Created On:{' '}
                            <span className="light-text">{job.createdAt}</span>
                          </Card.Text>

                          <Card.Text>
                            Status:{' '}
                            <span className="light-text">{job.jobStatus}</span>
                          </Card.Text>
                          <Card.Text>
                            Manager:{' '}
                            {job?.programManagers?.map((Manager, k) => (
                              <span className="light-text">
                                {Manager.managerName}
                              </span>
                            ))}
                          </Card.Text>
                        </Col>
                      </Row>
                      <Row>
                        {job.filePath && (
                          <Card.Text>
                            <div>
                              <a
                                href={`${constants.baseURL}/candidates/downloadProfile/${job.id}`}
                                download
                              >
                                Download File
                              </a>
                            </div>
                          </Card.Text>
                        )}
                        <Card.Subtitle className="mb-2">
                          {job?.skills?.map((Skill, k) => (
                            <span className="skill-tag-card">
                              {Skill.skill_name}
                            </span>
                          ))}
                          {userRole === 'ADMIN' && (
                            <Button
                              variant="primary"
                              className="card-button"
                              onClick={(e) => sendmail(job, e)}
                            >
                              <FontAwesomeIcon
                                className="fontAwosome_change"
                                icon={faEnvelope}
                              />
                              Send Mail
                            </Button>
                          )}
                          <Button
                            variant="primary"
                            className="card-button"
                            onClick={() => shareJob(job.id)}
                          >
                            <FontAwesomeIcon
                              className="fontAwosome_change"
                              icon={faShare}
                            />
                            Share Job
                          </Button>
                        </Card.Subtitle>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </div>
        </Row>
        <div className="center">
          {isFetching && <p>Fetching items...</p>}
          {!isFetching && HasMore && (
            <button
              type="submit"
              className="btn btn-primary ml-1"
              onClick={fetchData}
            >
              Load more
            </button>
          )}
        </div>
      </Container>
    </>
  );
}
export default withNavigate(FindListing);
