import { axiosPrivate } from './axios';
import { useAuth } from './auth';
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';

function BaseAPI() {
  const refresh = async () => {
    const response = await axiosPrivate.get('/refreshtoken', {
      headers: { isRefreshToken: 'true' },
    });
    document.cookie = 'token=' + response.data.data.token;
    return response.data.data.accessToken;
  };

  var token = '';
  if (!document.cookie == 0) {
    token = document.cookie
      .split('; ')
      .find((c) => c.startsWith('token'))
      .split('=')[1];
  }
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    // const responseIntercept = axiosPrivate.interceptors.response.use(
    //   (response) => {
    //     return response;
    //   },
    //   async (error) => {
    //     const prevRequest = error?.config;
    //     if (error?.response?.status === 401) {
    //       prevRequest.sent = true;
    //       const newAccessToken = refresh();
    //     //  console.log(newAccessToken);
    //            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
    //            return axiosPrivate(prevRequest);
    //     }
    //     return Promise.reject(error);
    //   }
    // );
    return () => {
      //axiosPrivate.interceptors.response.eject(responseIntercept);
      axiosPrivate.interceptors.request.eject(requestIntercept);
    };
  }, []);
  return axiosPrivate;
}
export default BaseAPI;
// export default axios.create({

//   baseURL: constants.baseURL,
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
//   },
// });
