import React, { useState, useEffect } from 'react';
// import { withRouter } from 'react-router-dom';
import withNavigate from '../views/HOC/useNavigate';
import BaseAPI from '../../service/axiosServices/baseAPI';
import UpdatePasswordModal from '../views/modal/updatePasswordModal';
import { Notyf } from 'notyf';
import { AuthContext } from '../../service/axiosServices/auth';
import { useContext } from 'react';

function Header({ navigate }) {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const username = localStorage.getItem('username');
  const axiosPrivate = BaseAPI();
  const [isActive, setActive] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const notyf = new Notyf({
    duration: 1000,
    dismissiblea: true,
    position: {
      x: 'right',
      y: 'top',
    },
  });

  const ToggleUserMenu = () => {
    setActive(!isActive);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAuth({});
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    window.location.pathname = '/';
    navigate(`/signin`);
  };

  const onUpdatePassword = (password) => {
    axiosPrivate
      .post(
        `/login/update`,
        JSON.stringify({
          username,
          password,
        })
      )
      .then((response) => {
        if (response.data.status === 'Success') {
          setModalShow(false)
          notyf.success('Password updated successfully !');
        } else {
          notyf.error('Something went wrong, please try again !');
        }
      });
  };
  let user = '';
  if(!username == 0){
     user = username.split('@')
    
    user = user[0]
  }

  return (
    <>
      <div className="header-container">
        <div className="logo-container">
          <img alt="Gisul" src="/assets/brand-logo.png" />{' '}
        </div>
        <div className="nav-container">
        {isAuthenticated &&  <span onClick={() => navigate('/')}>Home</span>}
        </div>
        <div className="nav-container">
        {isAuthenticated && <span onClick={() => navigate('/jobsListing')}>Trainings</span>}
        </div>
        <div className="nav-container">
         {isAuthenticated && <span onClick={() => navigate('/findjob')}>Find Trainings</span>} 
        </div>
        {isAuthenticated && (
          <div className="user-container" onClick={() => ToggleUserMenu()}>
            <img alt="User" src="/assets/user-icon.png" />
            <span className="dropdown-caret"></span>
            <div
              className={
                isActive
                  ? 'user-info-container user-info-container-show'
                  : 'user-info-container'
              }
            >
              <span>{`Welcome ${username}`}</span>
              <span onClick={() => navigate('/users')}>Users</span>
              <span onClick={() => setModalShow(true)}>Change Password</span>
              <span onClick={handleLogout}>Logout</span>
            </div>
          </div>
        )}
      </div>
      <UpdatePasswordModal
        show={modalShow}
        updatePasswordAction={onUpdatePassword}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default withNavigate(Header);
