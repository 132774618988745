import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';


function ProtectedRoute() {
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/signin" />;
  }
  return <Outlet />;
}

export default ProtectedRoute;
