import React, { useState, useEffect } from 'react';
import '../../App.css';
import BaseAPI from '../../service/axiosServices/baseAPI';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Notyf } from 'notyf';
import CreateUserModal from '../views/modal/createUserModal';
import EditUserModal from '../views/modal/editUserModal';
import withNavigate from '../views/HOC/useNavigate';

function Users({ navigate }) {
  const axiosPrivate = BaseAPI();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [actionShow, setActionShow] = useState(true);
  const userRole = localStorage.getItem('userRole');

  const notyf = new Notyf({
    duration: 1000,
    dismissiblea: true,
    position: {
      x: 'right',
      y: 'top',
    },
  });
  function actionCentre() {
    if (userRole === 'ADMIN') {
      setActionShow(false);
    }
  }
  const editUser = (id, username, roleName) => {
    localStorage.setItem('editRoleName', roleName);
    localStorage.setItem('editUsername', username);
    setModalShowEdit(true);
    // console.log(id,username,roleName);
  };
  const deleteUser = (id) => {
    axiosPrivate.delete(`/login/delete/${id}`).then((response) => {
      if (response.data.status === 'Success') {
        notyf.success('User record deleted successfully !');
        setIsLoading(false);
        fetchData();
      } else {
        notyf.error('Something went wrong, please try again !');
      }
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="action-wrapper">
        <span onClick={() => editUser(row.id, row.username, row.roleName)}>
          <img src="/assets/edit-icon.png" alt="" />
        </span>
        <span onClick={() => deleteUser(row.id)}>
          <img src="/assets/delete-icon.png" alt="" />
        </span>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'username',
      text: 'Username',
    },
    {
      dataField: 'roleName',
      text: 'Role',
    },
    {
      dataField: 'df1',
      isDummyField: true,
      formatter: actionFormatter,
      text: 'Actions',
      csvExport: false,
      hidden: actionShow,
    },
  ];

  async function fetchData() {
    const response = await axiosPrivate.get('/login/users');
    setUserData(response.data.data);
    setIsLoading(false);
  }

  const onCreate = (username, password, roleName) => {
    axiosPrivate
      .post(
        '/login/create',
        JSON.stringify({
          username,
          password,
          roleName,
        })
      )
      .then((response) => {
        if (response.data.status === 'Success') {
          notyf.success('User create successfully !');
          setModalShow(false);
          fetchData();
        } else {
          notyf.error('Something went wrong, please try again !');
        }
      });
  };

  const onEdit = (username, roleName) => {
    axiosPrivate
      .post(
        '/login/updateRoleName',{
          username: username,
          roleName: roleName
        }
      )
      .then((response) => {
        if (response.data.status === 'Success') {
          notyf.success('User updated successfully !');
          setModalShowEdit(false);
          fetchData();
        } else {
          notyf.error('Something went wrong, please try again !');
        }
      });
  };

  useEffect(() => {
    setIsLoading(false);
    fetchData();
    actionCentre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-box">
          <img className="loader-logo" src="assets/loader.png" alt="" />
          <div className="loader"></div>
        </div>
      )}
      <h2>Users List</h2>
      <div className="home-container content_box">
        <div className="row box-header">
          <div className="col-md-10">
            <div className="box-title">Users List</div>
          </div>
          <div className="col-md-2 text-right d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setModalShow(true)}
              className="btn btn-primary ml-1"
            >
              +
            </button>
          </div>
        </div>
        <BootstrapTable
          keyField="id"
          data={userData}
          columns={columns}
          striped
          hover
          condensed
          pagination={paginationFactory()}
        />
      </div>
      <CreateUserModal
        show={modalShow}
        createuseraction={onCreate}
        onHide={() => setModalShow(false)}
      />
      <EditUserModal
        show={modalShowEdit}
        edituseraction={onEdit}
        onHide={() => setModalShowEdit(false)}
      />
    </>
  );
}

export default withNavigate(Users);
