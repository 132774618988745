import CreateDataContext from '../createDataContext';

import BaseAPI from '../../service/axiosServices/baseAPI';
import axios from '../../service/axiosServices/axios';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'signin':
      return {
        isAuthenticated: true,
        userName: action.payload.firstName,
        isLoading: false,
        // accountID: action.payload.accountId
      };
    case 'signup':
      return {
        ...state,
        // accountID: action.payload.accountID,
        userName: action.payload.name,
      };
    case 'signout':
      return { isAuthenticated: null, errorMessage: '' };
    default:
      return state;
  }
};

const setIsLoading = (dispatch) => () => {
  dispatch({ type: 'set_isLoading' });
};

const signup = (dispatch) => async (name, contact) => {
  try {
    const response = await axios.post('/regOTP', { name, contact });
    // !(
    //   RootNavigation.navigate('RegisterOTP', { contactNumber: contact })
    // );
    dispatch({
      type: 'signup',
      payload: {
        // accountID: response.data.accountId,
        name,
      },
    });
    // }
  } catch (err) {
    dispatch({
      type: 'add_error',
      payload: 'Something went wrong with sign up',
    });
  }
};

const signin =
  (dispatch) =>
  async ({ username, password }) => {
    try {
      const response = await axios.post(
        '/login/validate',
        JSON.stringify({
          username,
          password,
        })
      );
      if (response.data.status === 'Success') {
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('userRole', response.data.data.roleName);
        localStorage.setItem('username', response.data.data.username);
        //   await AsyncStorage.setItem('firstName', response.data.data.firstName);
        //   await AsyncStorage.setItem('contact', mobileNumber);
        //   await AsyncStorage.setItem('accountId', response.data.data.accountId);
        dispatch({
          type: 'signin',
          payload: {
            isAuthenticated: true,
            username,
          },
        });
      } else if (response.status !== 200) {
        localStorage.removeItem('isAuthenticated');
        dispatch({
          type: 'add_error',
          payload: response.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: 'add_error',
        payload: 'Something went wrong with sign in',
      });
    }
  };

const signout = (dispatch) => async () => {
  //   await AsyncStorage.removeItem('token');
  dispatch({ type: 'signout' });
};

export const { Context, Provider } = CreateDataContext(
  authReducer,
  {
    signin,
    signout,
    signup,
    setIsLoading,
  },
  {
    isAuthenticated: false,
    // accountID: '',
    errorMessage: '',
    isLoading: true,
    userName: '',
  }
);
