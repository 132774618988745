import React from 'react';
import withNavigate from '../views/HOC/useNavigate';
import { useEffect, useState } from 'react';
import BaseAPI from '../../service/axiosServices/baseAPI';
import '../../App.css';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { Notyf } from 'notyf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { constants } from '../../constants';

function JobDetails(props) {
  const axiosPrivate = BaseAPI();
  const [jobData, setJobData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const urlElements = window.location.href.split('/');
  const jobId = urlElements[urlElements.length - 1];
  const notyf = new Notyf({
    duration: 2000,
    dismissiblea: true,
    position: {
      x: 'right',
      y: 'top',
    },
  });
  async function fetchJobDataWithId() {
    const response = await axiosPrivate.get(
      `/candidates/getcreatedjob/${jobId}`
    );
    if (response.data.status === 'Success') {
      setJobData(response.data.data);
      setIsLoading(false);
    } else {
      notyf.error('Something went wrong, please try again later !');
    }
  }
  useEffect(() => {
    fetchJobDataWithId();
  }, []);

  return (
    <>
      {' '}
      {isLoading && (
        <div className="loading-box">
          <img className="loader-logo" src="assets/loader.png" alt="" />
          <div className="loader"></div>
        </div>
      )}
      <Container>
        <Row style={{ padding: '20px' }}>
          <div>
            <Col xs={12} md={12} lg={12}>
              <Card className="card-design">
                <Card.Body>
                  <Card.Title className="job-title">
                    <span>{jobData.jobTitle}</span>
                    <Card.Subtitle
                      className="text-muted card-sub"
                      style={{ margin: 0, fontSize: '12px' }}
                    >
                      ID: {jobData.jobId}
                    </Card.Subtitle>
                  </Card.Title>
                  <Card.Text className="job-description">
                    {jobData.jobDesc}
                  </Card.Text>
                  <Card.Text>
                    <FontAwesomeIcon icon={faLocationDot} />{' '}
                    <span className="light-text">{jobData.jobLocation}</span>
                  </Card.Text>
                  <Row>
                  <Col xs={6} md={6} lg={6}>
                    <Card.Text>
                      Experince required:{' '}
                      <span className="light-text">{jobData.jobExperince}</span>
                    </Card.Text>
                    <Card.Text>
                      Job Mode:{' '}
                      <span className="light-text">{jobData.jobMode}</span>
                    </Card.Text>
                    <Card.Text>
                      Fulfilment by:{' '}
                      <span className="light-text">
                        {jobData.fulfillmentby}
                      </span>
                    </Card.Text>
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                    <Card.Text>
                      Created On:{' '}
                      <span className="light-text">{jobData.createdAt}</span>
                    </Card.Text>
                    <Card.Text>
                      Status:{' '}
                      <span className="light-text">{jobData.jobStatus}</span>
                    </Card.Text>
                    <Card.Text>
                      Manager:{' '}
                      {jobData?.programManagers?.map((Manager, k) => (
                        <span className="light-text">
                          {Manager.managerName}
                        </span>
                      ))}
                    </Card.Text>
                    </Col>
                  </Row>
                  {jobData.filePath && (
                    <Card.Text>
                      <div>
                        <a
                          href={`${constants.baseURL}/candidates/downloadProfile/${jobData.id}`}
                          download
                        >
                          Download File
                        </a>
                      </div>
                    </Card.Text>
                  )}
                  <Card.Subtitle className="mb-2">
                    {jobData?.skills?.map((Skill) => (
                      <span className="skill-tag-card">{Skill.skill_name}</span>
                    ))}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default withNavigate(JobDetails);
