import React, { useEffect, useState } from 'react';
import '../../App.css';
import BaseAPI from '../../service/axiosServices/baseAPI';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Notyf } from 'notyf';
// import { withRouter } from 'react-router-dom';
import withNavigate from '../views/HOC/useNavigate';
import BulkUploadModal from '../views/modal/bulkUploadModal';
import ToolkitProvider, {
  CSVExport,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { constants } from '../../constants';

const { ExportCSVButton } = CSVExport;

function Home({ navigate }) {
  const axiosPrivate = BaseAPI();
  const [apiResponseData, setAPIResponseData] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [actionShow, setActionShow] = useState(true);
  const userRole = localStorage.getItem('userRole');

  const notyf = new Notyf({
    duration: 2000,
    dismissiblea: true,
    position: {
      x: 'right',
      y: 'top',
    },
  });

  const deleteCandidate = (id) => {
    axiosPrivate.delete(`/candidates/${id}`).then((response) => {
      if (response.data.status === 'Success') {
        notyf.success('Candidate record deleted successfully !');
        setIsLoading(false);
        fetchData();
      } else {
        notyf.error('Something went wrong, please try again !');
      }
    });
  };

  const editCandidate = (id) => {
    navigate(`/candidate/${id}`);
  };

  const createCandidate = () => {
    navigate(`/createCandidate`);
  };

  const onSearch = (e) => {
    const searchString = e.target.value;
    if (searchString) {
      const updatedCandidateData = apiResponseData.filter((obj) => {
        const copyObj = { ...obj };
        delete copyObj.resumePath;
        delete copyObj.resumeLink;
        delete copyObj.idproofPath;
        return Object.values(copyObj).some((val) => {
          if (Array.isArray(val)) {
            const findIndex = val.find((skill) =>
              skill.skill_name
                .toLowerCase()
                .includes(searchString.toLowerCase())
            );
            return findIndex ? true : false;
          } else {
            console.log(val);
            return val
              ?.toString()
              .toLowerCase()
              .includes(searchString.toLowerCase());
          }
        });
      });
      setCandidateData(updatedCandidateData);
    } else {
      setIsLoading(false);
      setCandidateData(apiResponseData);
    }
  };

  const skillFormatter = (cell, row) => {
    return (
      <div>
        {cell.map((skill) => (
          <span className="skill-tag" key={`skill_${skill.id}`}>
            {skill.skill_name}
          </span>
        ))}
      </div>
    );
  };

  const resumeFormatter = (cell, row) => {
    return (
      <div>
        <a href={`${constants.baseURL}/candidates/download/${row.id}`} download>
          Download Resume
        </a>
      </div>
    );
  };

  const idProofFormatter = (cell, row) => {
    return (
      <div>
        {row.idproofPath && (
          <a
            href={`${constants.baseURL}/candidates/download/idProof/${row.id}`}
            download
          >
            Download Id Proof
          </a>
        )}
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="action-wrapper">
        <span onClick={() => editCandidate(row.id)}>
          <img src="/assets/edit-icon.png" alt="" />
        </span>
        <span onClick={() => deleteCandidate(row.id)}>
          <img src="/assets/delete-icon.png" alt="" />
        </span>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'trainerId',
      text: 'ID',
    },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'mobileNo',
      text: 'Phone',
    },
    {
      dataField: 'rating',
      text: 'Rating',
    },
    {
      dataField: 'skills',
      formatter: skillFormatter,
      csvFormatter: (cell, row, rowIndex) => {
        return cell.map((skill) => skill.skill_name).join(',');
      },
      text: 'Skills',
    },
    {
      dataField: 'idproofPath',
      formatter: idProofFormatter,
      text: 'Id Proof',
      csvExport: false,
    },
    {
      dataField: 'resumePath',
      formatter: resumeFormatter,
      text: 'Resume',
      csvExport: false,
    },
    {
      dataField: 'resumeLink',
      text: 'Resume',
      hidden: true,
    },
    {
      dataField: 'df1',
      isDummyField: true,
      formatter: actionFormatter,
      text: 'Actions',
      csvExport: false,
      hidden: actionShow,
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
  };

  async function fetchData() {
    const response = await axiosPrivate.post(
      '/candidates/getList',
      JSON.stringify({
        active: 1,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    setCandidateData(response.data.data);
    setAPIResponseData(response.data.data);
    setIsLoading(false);
  }

  const onBulkUpload = (file) => {
    setModalShow(false);
    const formData = new FormData();
    formData.append('files', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axiosPrivate
      .put('/candidates/bulkUpload', formData, config)
      .then((response) => {
        if (response.data.status === 'Success') {
          notyf.success('File uploaded successfully !');
          fetchData();
        } else if (response.data.status === 'Failure') {
          notyf.error(response.data.message);
        } else {
          notyf.error('File uploaded failed !');
        }
      });
  };
  function actionCentre() {
    if (userRole === 'ADMIN') {
      setActionShow(false);
    }
  }
  useEffect(() => {
    setIsLoading(false);
    fetchData();
    actionCentre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ToolkitProvider
      keyField="id"
      data={candidateData}
      columns={columns}
      exportCSV={{ onlyExportSelection: true, exportAll: true }}
    >
      {(props) => (
        <>
          {isLoading && (
            <div className="loading-box">
              <img className="loader-logo" src="assets/loader.png" alt="" />
              <div className="loader"></div>
            </div>
          )}
          <h2>Candidates List</h2>
          <div className="home-container content_box">
            <div className="row box-header">
              <div className="col-md-6">
                <div className="box-title">Candidates List</div>
              </div>
            </div>
            <div className="row box-header">
              <div className="col-md-8">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search..."
                    onKeyUp={(e) => onSearch(e)}
                  />
                </div>
              </div>
              {userRole === 'ADMIN' && (
                <div className="col-md-4 text-right d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => createCandidate()}
                    className="btn btn-primary ml-1"
                    style={{ margin: '0 5px' }}
                  >
                    +
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary ml-1"
                    onClick={() => setModalShow(true)}
                    style={{ margin: '0 5px' }}
                  >
                    Bulk Upload
                  </button>
                  <ExportCSVButton
                    {...props.csvProps}
                    className="btn-primary m1-1"
                    style={{ margin: '0 5px' }}
                  >
                    Export CSV!!
                  </ExportCSVButton>
                </div>
              )}
            </div>
            <div style={{ paddingBottom: '20px' }}>
              <BootstrapTable
                {...props.baseProps}
                striped
                hover
                condensed
                pagination={paginationFactory()}
                selectRow={selectRow}
              />
            </div>
          </div>
          <BulkUploadModal
            show={modalShow}
            bulkUploadAction={onBulkUpload}
            onHide={() => setModalShow(false)}
          />
        </>
      )}
    </ToolkitProvider>
  );
}

export default withNavigate(Home);
