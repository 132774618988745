import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter,Routes, Route } from 'react-router-dom';
import { Provider  } from './store/Auth/authContext';
import { AuthProvider } from '../src/service/axiosServices/auth'

import 'notyf/notyf.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter><Provider>
      <AuthProvider>
      <Routes>
      <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
      </Provider>
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
