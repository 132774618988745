import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function UpdatePasswordModal(props) {
  const { updatePasswordAction } = props;
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label>New Password</label>
          <input
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            className="form-control"
            placeholder="Enter new password"
          />
        </div>

        <div className="mb-3">
          <label>Verify New Password</label>
          <input
            type="password"
            onChange={(e) => setPassword2(e.target.value)}
            value={password2}
            className="form-control"
            placeholder="Verify password"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => updatePasswordAction(password1)}>Update</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdatePasswordModal;
