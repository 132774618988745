import React, { useState, useRef } from 'react';
import withNavigate from '../views/HOC/useNavigate';
import BaseAPI from '../../service/axiosServices/baseAPI';
import { Notyf } from 'notyf';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import { cityData } from '../../state-city-data';

const defaultFormState = {
  jobId: '',
  jobTitle: '',
  jobDesc: '',
  client: '',
  jobStatus: '',
  fulfillmentby: '',
  jobSkill: '',
  jobSkillName: '',
  jobMode: '',
  active: 1,
  jobLocation: '',
  jobExperince: '',
  file: null,
  filePath: '',
  programManager: '',
};
const animatedComponents = makeAnimated();
const notyf = new Notyf({
  duration: 2000,
  dismissiblea: true,
  position: {
    x: 'right',
    y: 'top',
  },
});

function CreateJobs(props) {
  const navigate = props.navigate;
  const axiosPrivate = BaseAPI();
  const [formState, setFormState] = useState(defaultFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [programManagerData, setProgramManagerData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const editCreatedJob = props?.match?.params?.id || '';
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [isReadOnly, setIsReadOnly] = useState(false);

  const formFieldChange = (e) => {
    const fieldName = e.target.getAttribute('data-name');
    const fieldVal = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: fieldVal,
    });
  };

  const onClientChange = (clientId) => {
    const selectedClientId = clientId.map((clientId) => clientId.value);
    setFormState({
      ...formState,
      client: [...selectedClientId].toString(),
    });
    fetchManagerData(selectedClientId.toString());
  };

  const onManagerChange = (manager) => {
    const selectedManagerId = manager.map((manager) => manager.value);
    setFormState({
      ...formState,
      programManager: [...selectedManagerId].toString(),
    });
  };
  const onJobStatusChange = (e) => {
    const fieldName = e.target.getAttribute('data-name');
    const fieldVal = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: fieldVal,
    });
    if (fieldVal === 'Close') {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  };

  const onFileChange = (e) => {
    setFormState({
      ...formState,
      files: e.target.files[0],
    });
  };

  async function onFormSubmit() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', formState.files);
    const payloadData = { ...formState };
    delete payloadData.files;
    formData.append('info', JSON.stringify({ ...payloadData }));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    //const response = await
    axiosPrivate
      .put('/candidates/createjob', formData, config)
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorMessage = error.response.data.message;
          const newErr = errorMessage.split(';');
          let newErrrr = newErr[0];
          notyf.error(newErrrr);
          //  console.log(error.response.data.message);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 'Success') {
          notyf.success('Job created successfully !');
          navigate('/jobsListing');
        } else {
          notyf.error('Something went wrong, please try again later !');
        }
      });
  }
  const oncancel = () => {
    navigate('/jobsListing');
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      notyf.error('Please fill up the complete form !');
    } else if (formValid) {
      onFormSubmit();
    }
  };

  React.useEffect(() => {
    let urlElements = window.location.href.split('/');
    if (!urlElements[urlElements.length - 1].match('CreateJobs')) {
      let editCreatedJob = urlElements[urlElements.length - 1];
      if (editCreatedJob)
        axiosPrivate
          .get(`/candidates/getcreatedjob/${editCreatedJob}`)
          .then((response) => {
            const createdJobData = response.data.data;
            const selectedSkills = createdJobData.skills.map(
              (skill) => skill.id
            );
            const selectedSkillsName = createdJobData.skills.map(
              (skill) => skill.value
            );
            setShowEdit(true);
            setFormState({
              ...createdJobData,
              jobSkill: [...selectedSkills].toString(),
              jobSkillName: [...selectedSkillsName].toString(),
            });
            if (createdJobData.jobStatus === 'Close') {
              setIsReadOnly(true);
            }
            fetchManagerData(createdJobData.client);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCreatedJob]);

  async function fetchManagerData(id) {
    const response = await axiosPrivate.get(`/candidates/manager/${id}`);
    setProgramManagerData(response.data.data);
  }
  React.useEffect(() => {
    async function fetchSkillData() {
      const response = await axiosPrivate.get('/candidates/getSkillList');
      setSkillData(response.data.data);
    }
    async function fetchClientData() {
      const response = await axiosPrivate.get('/candidates/getClientList');
      setClientData(response.data.data);
    }
    fetchSkillData();
    fetchClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSkillsChange = (skills) => {
    const selectedSkills = skills.map((skill) => skill.value);
    const selectedSkillsName = skills.map((skill) => skill.label);
    setFormState({
      ...formState,
      jobSkill: [...selectedSkills].toString(),
      jobSkillName: [...selectedSkillsName].toString(),
    });
  };
  const skillOptions = skillData.map((skill) => {
    return { value: skill.id, label: skill.skill_name };
  });
  const stateOptions = Object.keys(cityData).map((state) => {
    return { value: state, label: state };
  });
  const clientOptions = clientData.map((client) => {
    return { value: client.id, label: client.clientName };
  });
  const ManagerOptions = programManagerData.map((manager) => {
    return { value: manager.id, label: manager.managerName };
  });
  const cityOptions = formState.state
    ? cityData[formState.state].map((city) => {
        return { value: city, label: city };
      })
    : [];

  return (
    <>
      {isLoading && (
        <div className="loading-box">
          <img className="loader-logo" src="assets/loader.png" alt="logo" />
          <div className="loader"></div>
        </div>
      )}

      <div>
        <form>
          <div className="form_body">
            <div className="content_box">
              <div className="box-header with-border headingBox">
                <div className="box-title">
                  {showEdit && <h2>Update Training</h2>}
                  {!showEdit && <h2>Create Training</h2>}
                </div>
              </div>
              <div className="signup_box-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Preferrable Training Mode: :{' '}
                        <sup className="text-red">*</sup>
                      </label>
                      <select
                        className="form-control"
                        value={formState.jobMode}
                        data-name="jobMode"
                        onChange={formFieldChange}
                        disabled={isReadOnly}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('Training')
                        }
                      >
                        <option value="">Select Training Mode</option>
                        <option value="Online">Online</option>
                        <option value="In-Person">In-Person</option>
                        <option value="Online and Offline">Both</option>
                      </select>
                    </div>
                    {simpleValidator.current.message(
                      'Training',
                      formState.jobMode,
                      'required',
                      { className: 'text-danger' }
                    )}
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Job Id: <sup className="text-red">*</sup>
                      </label>
                      <input
                        data-name="jobId"
                        value={formState.jobId}
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Job ID"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('jobId')
                        }
                      />
                    </div>

                    {simpleValidator.current.message(
                      'jobId',
                      formState.jobId,
                      'required',
                      { className: 'text-danger' }
                    )}
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Training Title: <sup className="text-red">*</sup>
                      </label>
                      <input
                        data-name="jobTitle"
                        value={formState.jobTitle}
                        onChange={formFieldChange}
                        className="form-control"
                        disabled={isReadOnly}
                        placeholder="Training Title"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            'Training Title'
                          )
                        }
                      />
                    </div>
                    {simpleValidator.current.message(
                      'Training Title',
                      formState.jobTitle,
                      'required',
                      { className: 'text-danger' }
                    )}
                  </div>
                </div>
                {showEdit && (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Created Date:</label>
                        <input
                          type="text"
                          value={formState.createdAt}
                          onChange={formFieldChange}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    {formState.updatedAt && (
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Updated Date:</label>
                          <input
                            type="text"
                            value={formState.updatedAt}
                            onChange={formFieldChange}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Clients:</label>
                      <Select
                        options={clientOptions}
                        data-name="client"
                        components={animatedComponents}
                        value={clientOptions.filter((client) =>
                          formState.client?.split(',').includes(client.value.toString())
                        )}
                        isDisabled={isReadOnly}
                        onChange={onClientChange}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Manager:</label>
                      <Select
                        options={ManagerOptions}
                        data-name="programManager"
                        components={animatedComponents}
                        value={ManagerOptions.filter((manager) =>
                          formState.programManager?.split(',').includes(manager.value.toString())
                        )}
                        isDisabled={isReadOnly}
                        onChange={onManagerChange}
                        isMulti
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Fulfillment By: </label>
                      <select
                        className="form-control"
                        value={formState.fulfillmentby}
                        data-name="fulfillmentby"
                        disabled={isReadOnly}
                        onChange={formFieldChange}
                      >
                        <option value="">Select Fulfillment</option>
                        <option value="GISUL">Gisul</option>
                        <option value="other Vendor">Other Vendor</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Training Status: </label>
                      <select
                        className="form-control"
                        value={formState.jobStatus}
                        data-name="jobStatus"
                        onChange={onJobStatusChange}
                      >
                        <option value="">Select Status</option>
                        <option value="Open">Open</option>
                        <option value="In-Progress">In-Progress</option>
                        <option value="Close">Close</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Training Desc: <sup className="text-red">*</sup>
                      </label>
                      <textarea
                        data-name="jobDesc"
                        value={formState.jobDesc}
                        onChange={formFieldChange}
                        className="form-control"
                        disabled={isReadOnly}
                        placeholder="Training Desc"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            'Training Desc'
                          )
                        }
                      />
                    </div>

                    {simpleValidator.current.message(
                      'Training Desc',
                      formState.jobDesc,
                      'required',
                      { className: 'text-danger' }
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Training Skill: <sup className="text-red">*</sup>
                      </label>
                      <Select
                        data-name="jobSkill"
                        onChange={onSkillsChange}
                        options={skillOptions}
                        isDisabled={isReadOnly}
                        components={animatedComponents}
                        value={skillOptions.filter((skill) =>
                          formState.jobSkill?.split(',').includes(skill.value.toString())
                        )}
                        isMulti
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            'Training Skill'
                          )
                        }
                      />
                    </div>

                    {simpleValidator.current.message(
                      'Training Skill',
                      formState.jobSkill,
                      'required',
                      { className: 'text-danger' }
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>State:</label>
                      <select
                        className="form-control"
                        value={formState.state}
                        data-name="state"
                        disabled={isReadOnly}
                        onChange={formFieldChange}
                      >
                        <option value="">Select State</option>
                        {stateOptions.map((state, idx) => (
                          <option key={`stateVal_${idx}`} value={state.value}>
                            {state.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>City:</label>
                      <select
                        className="form-control"
                        value={formState.jobLocation}
                        data-name="jobLocation"
                        multi={true}
                        disabled={isReadOnly}
                        onChange={formFieldChange}
                      >
                        <option value="">Select City</option>
                        {cityOptions.map((city, idx) => (
                          <option key={`cityVal_${idx}`} value={city.value}>
                            {city.label}
                          </option>
                        ))}
                        {/* <option value="India">India</option>
                        <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Training Experince: <sup className="text-red">*</sup>
                      </label>
                      <input
                        data-name="jobExperince"
                        value={formState.jobExperince}
                        onChange={formFieldChange}
                        className="form-control"
                        disabled={isReadOnly}
                        placeholder="Training Experince"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor(
                            'Training Experince'
                          )
                        }
                      />
                    </div>

                    {simpleValidator.current.message(
                      'Training Experince',
                      formState.jobExperince,
                      'required|numeric|min:0,num',
                      { className: 'text-danger' }
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Attachment: {''}</label>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, application/msword"
                        onChange={onFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex justify-content-evenly">
                    <button
                      type="submit"
                      className="btn btn-primary ml-1 buttonJob"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary ml-1 buttonJob"
                      onClick={oncancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default withNavigate(CreateJobs);
