import React, { useRef, useState } from 'react';
import { Notyf } from 'notyf';
import withNavigate from '../views/HOC/useNavigate';
import BaseAPI from '../../service/axiosServices/baseAPI';
import axios from '../../service/axiosServices/axios';
import SimpleReactValidator from 'simple-react-validator';


const defaultFormState = {
  name: '',
  email: '',
  mobileNo: '',
  resumePath: '',
  active: 1,
  trainerId: '',
  countryCode: '',
  gender: '',
  baseLocation: '',
  oemCertified: '',
  certificationCompleted: null,
  clientWorkedFor: null,
  commercials: '',
  trainingMode: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  speakLanguage: '',
  rating: '',
  avgFeedback: '',
  associatedVia: '',
  vendorName: '',
  idProofType: '',
  idProofNo: '',
  totalTrainingDelivered: 0,
  totalExp: '',
  industryExp: '',
  trainingExp: '',
  skills: [],
  skillIds: null,
  files: null,
};
const notyf = new Notyf({
  duration: 1000,
  dismissiblea: true,
  position: {
    x: 'right',
    y: 'top',
  },
});

function SignUpAsCandidate(props) {
  const [formState, setFormState] = useState(defaultFormState);
  const axiosPrivate = BaseAPI();
  const [countryData, setCountryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const navigate = props.navigate;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();


const formFieldChange = (e) => {
  const fieldName = e.target.getAttribute('data-name');
  const fieldVal = e.target.value;
  setFormState({
    ...formState,
    [fieldName]: fieldVal,
  });
};

const onFileChange = (e) => {
  setFormState({
    ...formState,
    files: e.target.files[0],
  });
};

  async function onFormSubmit() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', formState.files);
    const payloadData = { ...formState };
    delete payloadData.files;
    formData.append('info', JSON.stringify({ ...payloadData }));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    //const response = await
    axiosPrivate.put('/candidates/create', formData, config).then(response => {
      setIsLoading(false);
      if (response.data.status === 'Success') {
        notyf.success('Candidate created successfully !');
        navigate('/');
      } else {
        notyf.error('Something went wrong, please try again later !');
      }
    });
  }

  const countryCodeOptions = countryData.map((country) => {
    return { value: country.id, label: country.phonecode, label2: country.name };
  });
  React.useEffect(() => {
    async function fetchCountryData() {
      const response = await axios.get('/location/getcountry');
      setCountryData(response.data.data);
    }
    fetchCountryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      notyf.error('Please fill up the complete form !');
    } else if (formValid) {
      onFormSubmit();
    }
  }

  return (
    <>
      {isLoading && (
        <div className="loading-box">
          <img className="loader-logo" src="assets/loader.png" alt="" />
          <div className="loader"></div>
        </div>
      )}
      <div>
        <form>
          <div className="form_body">
            <div className="signup_content_box">
              <div className="box-header with-border headingBox">
                <div className="box-title">Sign up Candidate Details</div>
              </div>
              <div className="signup_box-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Name: <sup className="text-red">*</sup>
                      </label>
                      <input
                        value={formState.name}
                        data-name="name"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Name"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('name')
                        }
                      />
                      {simpleValidator.current.message(
                        'name',
                        formState.name,
                        'required',
                        { className: 'text-danger' }
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Email: <sup className="text-red">*</sup>
                      </label>
                      <input
                        value={formState.email}
                        data-name="email"
                        type="email"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Email"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('email')
                        }
                      />
                      {simpleValidator.current.message(
                        'email',
                        formState.email,
                        'required',
                        { className: 'text-danger' }
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Trainer ID:</label>
                      <input
                        value={formState.trainerId}
                        data-name="trainerId"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Trainer ID"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Gender:</label>
                      <select
                        className="form-control"
                        value={formState.gender}
                        data-name="gender"
                        onChange={formFieldChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Trans">Trans</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Country Code:</label>
                      <select
                        className="form-control"
                        value={formState.countryCode}
                         data-name="countryCode"
                        onChange={formFieldChange}
                      >
                        <option value="">Select Country Code</option>
                        {countryCodeOptions.map((countryCode, idx) => (
                          <option key={`countryVal_${idx}`} value={countryCode.value}>
                            {countryCode.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Mobile No: <sup className="text-red">*</sup>
                      </label>
                      <input
                        value={formState.mobileNo}
                        type="number"
                        data-name="mobileNo"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Mobile Number"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('mobileNo')
                        }
                      />
                      {simpleValidator.current.message(
                        'mobileNo',
                        formState.mobileNo,
                        'required',
                        { className: 'text-danger' }
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Language:</label>
                      <input
                        value={formState.speakLanguage}
                        type="text"
                        data-name="speakLanguage"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Language"
                      />
                    </div>
                  </div>
               
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Resume: <sup className="text-red">*</sup>
                    </label>
                    <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, application/msword"
                        onChange={onFileChange}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('files')
                        }
                      />
                       {simpleValidator.current.message(
                          'files',
                          formState.files,
                          'required',
                          { className: 'text-danger' }
                        )}
                  </div>
                 
                </div>
              </div>
            </div>
              <div className="col d-flex justify-content-around">
                <button
                  type="submit"
                  className="btn btn-primary ml-1"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default withNavigate(SignUpAsCandidate);
