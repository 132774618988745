import { useState } from 'react';

import { createContext, useContext } from 'react';

export const AuthContext = createContext('');

export const calculateTime = (expirationTime) => {
  const currentDate = new Date().getTime();
 // console.log(currentDate);
  const adjExpirationTime = new Date(expirationTime * 1000).getTime();
 // console.log(adjExpirationTime);
  const remainingTime = adjExpirationTime - currentDate;
  //console.log(remainingTime);
  return remainingTime;
};

export const AuthProvider = ({ children }) => {
  //const accessToken = document.cookie.split('; ').find((c) => c.startsWith('token')).split('=')[1]
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);
