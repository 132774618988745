import React, { useState, useRef } from 'react';
import BaseAPI from '../../service/axiosServices/baseAPI';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Notyf } from 'notyf';
// import { withRouter, useHistory } from 'react-router-dom';
import withNavigate from '../views/HOC/useNavigate';
import { cityData } from '../../state-city-data';
import axios from '../../service/axiosServices/axios';
import SimpleReactValidator from 'simple-react-validator';

const defaultFormState = {
  name: '',
  email: '',
  mobileNo: '',
  resumePath: '',
  active: 1,
  trainerId: '',
  countryCode: '',
  gender: '',
  baseLocation: '',
  oemCertified: '',
  certificationCompleted: null,
  clientWorkedFor: null,
  commercials: '',
  trainingMode: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  speakLanguage: '',
  rating: '',
  avgFeedback: '',
  associatedVia: '',
  vendorName: '',
  idProofType: '',
  idProofNo: '',
  idproofPath: '',
  totalTrainingDelivered: 0,
  totalExp: '',
  industryExp: '',
  trainingExp: '',
  skills: [],
  skillIds: null,
  files: null,
  idProofFile: null,
};

const animatedComponents = makeAnimated();
const notyf = new Notyf({
  duration: 2000,
  dismissiblea: true,
  position: {
    x: 'right',
    y: 'top',
  },
});

function CreateCandidate(props) {
  const editCandidateID = props?.match?.params?.id || '';
  // const navigate = useHistory();
  const axiosPrivate = BaseAPI();
  const navigate = props.navigate;
  const [formState, setFormState] = useState(defaultFormState);
  const [skillData, setSkillData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [vendoreData, setVendorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [fileEditOption, setFileEditOption] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const formFieldChange = (e) => {
    const fieldName = e.target.getAttribute('data-name');
    const fieldVal = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: fieldVal,
    });
  };

  const onSkillsChange = (skillIds) => {
    const selectedSkillIds = skillIds.map((skill) => skill.value);
    setFormState({
      ...formState,
      skillIds: [...selectedSkillIds],
    });
  };

  const onVendorChange = (vendor) => {
    const selectedVendor = vendor.map((vendor) => vendor.value);
    setFormState({
      ...formState,
      vendorName: [...selectedVendor].toString(),
    });
  };

  const onClientChange = (clientId) => {
    const selectedClientId = clientId.map((clientId) => clientId.value);
    setFormState({
      ...formState,
      clientWorkedFor: [...selectedClientId].toString(),
    });
  };
  const onCertificateChange = (certificateId) => {
    const selectedCertificateId = certificateId.map(
      (certificateId) => certificateId.value
    );
    setFormState({
      ...formState,
      certificationCompleted: [...selectedCertificateId].toString(),
    });
  };

  const onFileChange = (e) => {
    setFormState({
      ...formState,
      files: e.target.files[0],
    });
  };

  const onidProofFileChange = (e) => {
    setFormState({
      ...formState,
      idProofFile: e.target.files[0],
    });
  };

  async function fetchStateData(country_id) {
    const response = await axios.get(`/location/states/${country_id}`);
    setStatesData(response.data.data);
  }

  async function fetchCityData(states_id) {
    const response = await axios.get(`/location/city/${states_id}`);
    setCityData(response.data.data);
  }

  const countryFieldChange = (e) => {
    const fieldName = e.target.getAttribute('data-name');
    const fieldVal = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: fieldVal,
    });
    fetchStateData(fieldVal);
    fetchCityData();
  };

  const stateFieldChange = (e) => {
    const fieldName = e.target.getAttribute('data-name');
    const fieldVal = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: fieldVal,
    });
    fetchCityData(fieldVal);
  };

  React.useEffect(() => {
    async function fetchSkillData() {
      const response = await axiosPrivate.get('/candidates/getSkillList');
      setSkillData(response.data.data);
    }
    async function fetchClientData() {
      const response = await axiosPrivate.get('/candidates/getClientList');
      setClientData(response.data.data);
    }
    async function fetchCertificatetData() {
      const response = await axiosPrivate.get('/candidates/getCertificateList');
      setCertificateData(response.data.data);
    }
    async function fetchCountryData() {
      const response = await axios.get('/location/getcountry');
      setCountryData(response.data.data);
    }
    async function fetchVendorData() {
      const response = await axiosPrivate.get('/candidates/getVendor');
      setVendorData(response.data.data);
    }
    fetchSkillData();
    fetchClientData();
    fetchCertificatetData();
    fetchCountryData();
    fetchVendorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let urlElements = window.location.href.split('/');
    if (!urlElements[urlElements.length - 1].match('createCandidate')) {
      let editCandidateID = urlElements[urlElements.length - 1];
      if (editCandidateID)
        axiosPrivate.get(`/candidates/${editCandidateID}`).then((response) => {
          const candidateData = response.data.data;
          const selectedSkills = candidateData.skills.map((skill) => skill.id);
          setFileEditOption(false);
          setFormState({
            ...candidateData,
            skillIds: [...selectedSkills],
          });
          setShowEdit(true);
          if (candidateData.country) {
            fetchStateData(candidateData.country);
            if (candidateData.state) fetchCityData(candidateData.state);
          }
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCandidateID]);

  const skillOptions = skillData.map((skill) => {
    return { value: skill.id, label: skill.skill_name };
  });
  const clientOptions = clientData.map((client) => {
    return { value: client.id, label: client.clientName };
  });
  const certificateOptions = certificateData.map((certificate) => {
    return { value: certificate.id, label: certificate.certificateName };
  });
  const vendorOptions = vendoreData.map((vendor) => {
    return { value: vendor.id, label: vendor.vendorName };
  });
  const countryCodeOptions = countryData.map((country) => {
    return {
      value: country.id,
      label: country.phonecode,
      label2: country.name,
    };
  });
  const stateOptions = statesData.map((state) => {
    return { value: state.id, label: state.name };
  });
  const cityOptions = cityData.map((city) => {
    return { value: city.id, label: city.name };
  });

  async function onFormSubmit() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', formState.files);
    formData.append('idProofFile', formState.idProofFile);

    const payloadData = { ...formState };
    delete payloadData.files;
    delete payloadData.idProofFile;
    formData.append('info', JSON.stringify({ ...payloadData }));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    //const response = await
    axiosPrivate
      .put('/candidates/create', formData, config)
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorMessage = error.response.data.message;
          const newErr = errorMessage.split(';');
          let newErrrr = newErr[0];
          notyf.error(newErrrr);
          //  console.log(error.response.data.message);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === 'Success') {
          notyf.success('Candidate created successfully !');
          navigate('/');
        } else {
          notyf.error('Something went wrong, please try again later !');
        }
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let formValid = null;
    if (
      fileEditOption === false &&
      simpleValidator.current.fieldValid('name') === true &&
      simpleValidator.current.fieldValid('email') === true &&
      simpleValidator.current.fieldValid('mobileNo') === true
    ) {
      onFormSubmit();
    } else {
      formValid = simpleValidator.current.allValid();
    }
    if (!formValid && fileEditOption === true) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      notyf.error('Please fill up the complete form !');
    } else if (formValid) {
      onFormSubmit();
    }
  };
  //const fileInputFill = formState.resumePath.split('/');
  //const newFileName = fileInputFill[fileInputFill.length -1].split()
  // const selectedSkillIds = formState.skills; //.map(skill => skill.id);
  // console.log(selectedSkillIds);
  // // skillOptions.length > 1 ? formState.skills.map(skill => skill.id) : [];

  return (
    <>
      {isLoading && (
        <div className="loading-box">
          <img className="loader-logo" src="assets/loader.png" alt="logo" />
          <div className="loader"></div>
        </div>
      )}
      {showEdit && <h2>Update Candidate</h2>}
      {!showEdit && <h2>Create Candidate</h2>}
      <div>
        <form>
          <div className="form_body">
            <div className="content_box">
              <div className="box-header with-border headingBox">
                <div className="box-title">Personal Details</div>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Name: <sup className="text-red">*</sup>
                      </label>
                      <input
                        value={formState.name}
                        data-name="name"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Name"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('name')
                        }
                      />

                      {simpleValidator.current.message(
                        'name',
                        formState.name,
                        'required',
                        { className: 'text-danger' }
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Email:<sup className="text-red">*</sup>
                      </label>
                      <input
                        value={formState.email}
                        data-name="email"
                        type="email"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Email"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('email')
                        }
                      />
                      {simpleValidator.current.message(
                        'email',
                        formState.email,
                        'required',
                        { className: 'text-danger' }
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Trainer Id:<sup className="text-red"></sup>
                      </label>
                      <input
                        value={formState.trainerId}
                        data-name="trainerId"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Trainer ID"
                      />
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Gender:</label>
                      <select
                        className="form-control"
                        value={formState.gender}
                        data-name="gender"
                        onChange={formFieldChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Country Code:<sup className="text-red">*</sup>
                      </label>
                      <select
                        className="form-control"
                        value={formState.countryCode}
                        data-name="countryCode"
                        onChange={formFieldChange}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('countryCode')
                        }
                      >
                        <option value="">Select Country Code</option>
                        {countryCodeOptions.map((countryCode, idx) => (
                          <option
                            key={`countryVal_${idx}`}
                            value={countryCode.value}
                          >
                            {countryCode.label}
                          </option>
                        ))}
                      </select>

                      {simpleValidator.current.message(
                        'countryCode',
                        formState.countryCode,
                        'required',
                        { className: 'text-danger' }
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Mobile No: <sup className="text-red">*</sup>
                      </label>
                      <input
                        value={formState.mobileNo}
                        type="number"
                        data-name="mobileNo"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Mobile Number"
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('mobileNo')
                        }
                      />

                      {simpleValidator.current.message(
                        'mobileNo',
                        formState.mobileNo,
                        'required',
                        { className: 'text-danger' }
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Language:</label>
                      <input
                        value={formState.speakLanguage}
                        type="text"
                        data-name="speakLanguage"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Language"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content_box">
              <div className="box-header with-border headingBox">
                <div className="box-title">Address Details</div>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Base Location:</label>
                      <input
                        value={formState.baseLocation}
                        data-name="baseLocation"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Base Location"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Country:</label>
                      <select
                        className="form-control"
                        value={formState.country}
                        data-name="country"
                        onChange={countryFieldChange}
                      >
                        <option value="">Select Country</option>
                        {countryCodeOptions.map((countryCode, idx) => (
                          <option
                            key={`countryVal_${idx}`}
                            value={countryCode.value}
                          >
                            {countryCode.label2}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>State:</label>
                      <select
                        className="form-control"
                        value={formState.state}
                        data-name="state"
                        onChange={stateFieldChange}
                      >
                        <option value="">Select State</option>
                        {stateOptions.map((state, idx) => (
                          <option key={`stateVal_${idx}`} value={state.value}>
                            {state.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>City:</label>
                      <select
                        className="form-control"
                        value={formState.city}
                        data-name="city"
                        onChange={formFieldChange}
                      >
                        <option value="">Select city</option>
                        {cityOptions.map((city, idx) => (
                          <option key={`cityVal_${idx}`} value={city.value}>
                            {city.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Zipcode:</label>
                      <input
                        value={formState.zipcode}
                        data-name="zipcode"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Zipcode"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Address:</label>
                      <input
                        value={formState.address}
                        type="text"
                        data-name="address"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content_box">
              <div className="box-header with-border headingBox">
                <div className="box-title">Professional Details</div>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Skills:</label>
                      <Select
                        options={skillOptions}
                        data-name="skills"
                        components={animatedComponents}
                        value={skillOptions.filter((skill) =>
                          formState.skillIds?.includes(skill.value)
                        )}
                        onChange={onSkillsChange}
                        isMulti
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Associated Via:</label>
                      <select
                        className="form-control"
                        value={formState.associatedVia}
                        data-name="associatedVia"
                        onChange={formFieldChange}
                      >
                        <option value="">Select Association</option>
                        <option value="Direct">Direct</option>
                        <option value="Vendor">Vendor</option>
                      </select>
                    </div>
                  </div>
                  {formState.associatedVia === 'Vendor' && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Vendor Name:</label>
                        <Select
                          options={vendorOptions}
                          data-name="vendorName"
                          components={animatedComponents}
                          value={vendorOptions.filter((vendor) =>
                            formState.vendorName
                              ?.split(',')
                              .includes(vendor.value.toString())
                          )}
                          onChange={onVendorChange}
                          isMulti
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>OEM Certified:</label>
                      <select
                        className="form-control"
                        value={formState.oemCertified}
                        data-name="oemCertified"
                        onChange={formFieldChange}
                      >
                        <option value="">Select OEM Certification</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Certifications Completed: </label>
                      <Select
                        options={certificateOptions}
                        data-name="certificationCompleted"
                        components={animatedComponents}
                        value={certificateOptions.filter((certificate) =>
                          formState.certificationCompleted
                            ?.split(',')
                            .includes(certificate.value.toString())
                        )}
                        onChange={onCertificateChange}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Clients Worked For:</label>
                      <Select
                        options={clientOptions}
                        data-name="clientWorkedFor"
                        components={animatedComponents}
                        value={clientOptions.filter((client) =>
                          formState.clientWorkedFor
                            ?.split(',')
                            .includes(client.value.toString())
                        )}
                        onChange={onClientChange}
                        isMulti
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Total Training Delivered: </label>
                      <input
                        value={formState.totalTrainingDelivered}
                        type="number"
                        data-name="totalTrainingDelivered"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Total Trainings Delivered"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Total Experience: </label>
                      <input
                        value={formState.totalExp}
                        type="number"
                        data-name="totalExp"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Total Experience"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Industry Experience: </label>
                      <input
                        value={formState.industryExp}
                        type="number"
                        data-name="industryExp"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Industry Experience"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Training Experience: </label>
                      <input
                        value={formState.trainingExp}
                        type="number"
                        data-name="trainingExp"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Training Experience"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Commercials:</label>
                      <input
                        value={formState.commercials}
                        type="text"
                        data-name="commercials"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Commercials"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Rating:</label>
                      <input
                        value={formState.rating}
                        type="number"
                        step=".1"
                        data-name="rating"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="Rating"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Average Feedback:</label>
                      <select
                        className="form-control"
                        value={formState.avgFeedback}
                        data-name="avgFeedback"
                        onChange={formFieldChange}
                      >
                        <option value="">Select Feedback</option>
                        <option value="Excellent">Excellent</option>
                        <option value="Good">Good</option>
                        <option value="Fair">Fair</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Preferrable Training Mode: </label>
                      <select
                        className="form-control"
                        value={formState.trainingMode}
                        data-name="trainingMode"
                        onChange={formFieldChange}
                      >
                        <option value="">Select Training Mode</option>
                        <option value="Online">Online</option>
                        <option value="In-Person">In-Person</option>
                        <option value="Online and Offline">Both</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>ID Proof Type:</label>
                      <select
                        className="form-control"
                        value={formState.idProofType}
                        data-name="idProofType"
                        onChange={formFieldChange}
                      >
                        <option value="">Select ID Type</option>
                        <option value="Aadhaar Card">Aadhaar Card</option>
                        <option value="Pan Card">PAN Card</option>
                        <option value="Voter Card">Voter Card</option>
                        <option value="Passport">Passport</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>ID Proof Number:</label>
                      <input
                        value={formState.idProofNo}
                        type="text"
                        data-name="idProofNo"
                        onChange={formFieldChange}
                        className="form-control"
                        placeholder="ID Number"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>IdProof:</label>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, application/msword"
                        onChange={onidProofFileChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Resume: {showEdit && <sup className="text-red"></sup>}
                        {!showEdit && <sup className="text-red">*</sup>}
                      </label>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, application/msword"
                        onChange={onFileChange}
                        onBlur={() =>
                          simpleValidator.current.showMessageFor('files')
                        }
                      />
                      {fileEditOption === true &&
                        simpleValidator.current.message(
                          'files',
                          formState.files,
                          'required',
                          { className: 'text-danger' }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-around">
              <button
                type="submit"
                className="btn btn-primary ml-1"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default withNavigate(CreateCandidate);
